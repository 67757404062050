* {
    margin: 0; 
    padding: 0;
  }

  html {
    height: 100%;
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 78vh;
}
  ::-moz-selection { /* Code for Firefox */
    color: rgb(255, 255, 255);
    background: rgb(60, 190, 169);
  }
  
  ::selection {
    color: rgb(255, 255, 255);
    background: rgb(60, 190, 169);
  }
.header {
    margin-top: -2rem;
    margin-left: 115px;
    width: 80%;  
    justify-content: center;
  }
 
.header-item {
    width: 92%; 
    margin-top: 0px;
    align-content: center;
  }  
.header > h1 { 
    font-size: 44px;
    font-weight: 600;
    margin-top: 75px;
    align-content: center;
    margin-bottom: 15px;
}
.header > h2 { 
  font-size: 25px;
  font-weight: 450;
  margin-top: 80px;
  margin-bottom: 20px;
  width: 95%;
}
.sub-header > h2 { 
  font-size: 25px;
  font-weight: 450;
  margin-top: 40px;
  margin-left: 120px;
  margin-bottom: 20px;
  width: 60%;
}
.header > p {
  font-size: 18px;
  width: 90%;
  margin-top: 50px;
  margin-bottom: 45px;
}
.bullets > p {
  font-size: 18px;
  width: 90%;
  margin-top: 0px;
  margin-left: 140px;
  margin-bottom: 40px;
}
.span-effect{
color:rgb(60, 190, 169);
}

.text-highlight{
  background: rgb(60, 190, 169);
  color: white;
}
.numbers{
  color:rgb(60, 190, 169);
}
.service-container{
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 0px;
  list-style: none;
  width: 80%;
  align-items: right;
  object-fit: cover;
}
.service-container-A{
  min-width: 60%;
}

.service-container-B{
  min-width: 60%;
}
.service-container-C{
  min-width: 60%;
}
.jobs-container {
  margin-left: -55px;
}
.service-container-detail{
  margin-left: 115px;
  width: 80%;
  margin-top: 20px;
  font-size: 20px;
}
.space_bottom{
  margin-bottom: 180px;
}

.arrow-container {
  display: flex;
  margin-bottom: -20px;
}

.content-link > h3 { 
    margin-top: 25px;
    margin-left: 115px;
    font-size: 25px;
    font-weight: 600;
}

.icon-container {
  float:right;
  color:  rgb(0, 0, 0);
  font-size: 55px;
  margin-left: 0px;
  margin-top: -13px;
}
.icon-container:hover{
  /*transform: translateX(10px);*/
  color: rgb(60, 190, 169);

}

/* Email Hoover */
.content-link:hover{
    text-decoration: none;
    color: black;
  }
.content-link:visited {
    text-decoration: none;
    color:rgb(0, 0, 0);
  }
.conent-link:focus { 
    text-decoration: "none";
    text-decoration: rgb(0, 0, 0);
  }
.content-link:active{
    text-decoration: none;
    color:rgb(0, 0, 0);
  }
.content-link:link{
    text-decoration: none;
    color:rgb(0, 0, 0);
  }


 /* Button */
  .hero-btns {
    margin-top: 45px;
    margin-left: 110px;
  }
  .hero-btns .btn {
    margin: 6px;
  }
  .hero-btns .btn:hover {
    border-color: rgb(60, 190, 169);
  }
  
  /* Size 2 - Tablet */
  @media screen and (max-width: 960px) {
    .header {
        margin-top: 0px;
        margin-left: 80px;
        width: 90%;  
     }  
    .header-item {
        margin-left: 0px;
        width: 90%; 
        margin-top: 0px;
     }  
    .header >h1 {
        font-size: 35px;
        margin-top: 30px;
        width: 80%;
      }
    .header >h2{
        font-size: 24px;
        margin-top: 45px;
        margin-bottom: 50px;
        width: 80%;
      }
      .header > p {
        margin-top: -30px;
        margin-bottom: 40px;
      }
      
      .bullets > p {
        margin-top: -30px;
        margin-bottom: 40px;
      }
      
      .sub-header > h2 { 
        font-size: 22px;
        font-weight: 450;
        margin-top: 40px;
        margin-left: 80px;
        margin-bottom: 20px;
        width: 50%;
      }
    .content-link > h3 { 
        margin-left: 40px;
        font-size: 25px;
      }
    .icon-container {
      float:right;
      color: rgb(0, 0, 0);
      font-size: 45px;
      margin-left: 0px;
      margin-top: -9px;
      }
    .icon-container:hover {
      color: rgb(60, 190, 169);
    }
    
      /* Button - Size 2 - Tablet */
     .btn-mobile {
      display:block;
      text-decoration: none;
      margin-left: -35px;
     }

    .btn {
      width: 30%; 
     }    
     .service-container{
      display: flex;
      box-sizing:border-box;
      object-fit: cover;
      width: 75%;
      align-items: right;
      object-fit: cover;
    }
    .service-container-A{
      min-width: 40%;
      align-items: right;
    }
    
    .service-container-C{
      min-width: 40%;
    }
    .service-container-detail{
      margin-left: 80px;
      width: 80%;
      margin-top: 20px;
    }
  .content-link{
    margin-left: 40px;
  }
}

/* Size 3 - Mobile */
@media screen and (max-width: 768px) {
    .header {
        margin-top: -1rem;
        margin-left: 20px;
        width: 100%;  
      } 
      
    .header-item {
        margin-left: 20px;
        width: 90%; 
        margin-top: 20px;
      } 
      
    .header > h1 {
        color:rgb(0, 0, 0);
        font-size: 24px;
        margin-top: 100px;
      }

    .header > h2 {
      color:rgb(0, 0, 0);
        font-size: 20px;
        width: 80%;
        margin-top: 70px;
        margin-bottom: 20px;
      }
      .sub-header > h2 { 
        font-size: 18px;
        font-weight: 450;
        margin-top: 40px;
        margin-left: 20px;
        margin-bottom: 20px;
        width: 70%;
      }
      .header > p {
        font-size: 16px;
        width: 90%;
        margin-top: 35px;
        margin-bottom: 40px;
      }
      .bullets > p {
        font-size: 16px;
        width: 90%;
        margin-top: 10px;
        margin-left: 40px;
        margin-bottom: 40px;
      }
    .content-link > h3 { 
        margin-left: 20px;
        font-size: 20px;
        margin-top: 45px;
     }
    
     
    .arrow-container{
      display: flex;
      margin-left: 0px;
      margin-top: 10px;
      width: 450px;
    }
    
    .icon-container{
      float:right;
      color: rgb(60, 190, 169);
      font-size: 42px;
      margin-bottom: -45px;
      margin-top: -8px;
      margin-left: -4px;
      animation: move-text 5.75s forwards;
    }
    .icon-container:hover{
      color: rgb(60, 190, 169);
    }
    
    .btn-mobile {
      display: block;
      text-decoration: none;
      margin-left: -95px;
    }
    .btn {
      width: 70%; 
    }
    .header-item {
      width: 92%; 
      margin-top: -20px;
      margin-left: 0px;
      align-content: center;
    }  
    .service-container{
      margin-top: 45px;
      display: list-item;
    }
    .service-container-A{
      width: 120%;
    }
    
    .service-container-C{
      width: 120%;
    }
    .service-container-detail{
      margin-left: 20px;
      margin-top: 15px;
    }
    .numbers{
      color: rgb(60, 190, 169);
    }
  .space_bottom{
    margin-top: 300px;
  }
  .content-link{
    margin-left: 0px;

  }
}
.navbar {
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  height: 140px;
  display: flex;
  font-size: 1.1rem;
  top: 0;
  z-index: 999;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  object-fit: contain;
  margin-left: 50px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.navbar-container {
  display: flex;
  height: 100px;
  max-width: 2500px;
}

.navbar-logo {
  font-weight: 80;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none;
}
img.resize {
  width: 295px;
  height: 55px; 
}

.nav-item {
  height: 90px; 
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  justify-content: right;
  margin-left: auto;
}

.nav-links { 
  color: rgb(7, 7, 7);
  display: flex;
  text-decoration: none;
  padding: 1rem 1rem; 
}

.nav-links:hover {
  border-bottom:  rgb(60, 190, 169) 0.125em solid;
  margin-top:3px;
  transition: all 0.2s ease-out;
}

.active{
  border-bottom: #000000 0.125em solid;
  margin-top:3px;
  border-color: rgb(60, 190, 169);
}
.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}

/* TABLET */
@media screen and (max-width: 960px) {
.menu-icon {
    display: block;
    position: absolute;
    top: 10px;
    bottom: 50;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
.fa-times { color: rgb(0, 0, 0); font-size: 2rem; }
.fa-bars {color: rgb(0, 0, 0); font-size: 1.7rem; }

.navbar-logo { margin-left: -35px;}

.nav-menu { /*menu bar container */
    display: flex;
    flex-direction:column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
.nav-menu.active { /* ACTIVE menu bar container */
    background: rgb(255, 255, 255);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    font-size: 20px; /* change size of font in mobile menu */
    font-style:bold;
    z-index: 1;
  }
.nav-links { /*after clicking links TABLETT */
    text-align: center;
    padding: 5rem 0rem;
    width: 100%;
    color: rgb(7, 7, 7);
    display: table;
    border-bottom: none;
    border-color: none;
  }
.nav-links:hover {
    color: rgb(60, 190, 169);
    transition: none;
    border-radius: 0;
    border-bottom: none;
    border-color: none;
  }
} 
/* MOBILE */
@media screen and (max-width: 768px) {
  .navbar {
    height: 65px;
    font-size: 1rem;
  }
  .nav-menu.active { /* after clicking hamburger menu */
    background: rgb(255, 255, 255);
    left: 0;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed; /* 1 */
    overflow-y: hidden;
  }
  .navbar-logo {
    padding-top: 3%;
    margin-left: -35px;
    margin-top: 25px;
   }
   img.resize {
    width: 200px;
    height: 35px; 
  }
  .nav-links { /* Change navbar links css here */
    color: rgb(7, 7, 7);
    text-decoration: none;
  }
  .nav-links:hover {
    border-bottom: none;
    transition: none;
  } 
  .active {
    border-bottom: none;
    border-color: none;
  }
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;  
}
.page {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.home,
.services,
.careers,
.CoreValues,
.openpositions,
.news,
.rustdeveloper,
.golangdeveloper,
.blockchainsolutionsengineer,
.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
.CoreValues {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;

}
.careers {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(0, 0, 0);
  font-size: 100px;
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}
.news {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.contact {
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openpositions {
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.rustdeveloper {
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.golangdeveloper {
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.blockchainsolutionsengineer {
  font-size: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
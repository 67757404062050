
body {
  position: relative;
  margin: 0;
 /* padding-bottom: 6rem; */
  min-height: 100%;
}
.footer-bottom-sticky{
  position:absolute;
  left:0;
  bottom:0;
  padding: 20px;
  right:0;
 }

.footer-container{
  display: flex;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  margin-top: auto;
  max-width: 2500px;
  font-size: 16px;
  margin-left: 5px;
  padding: 1.5rem 0rem;

}
.footer-column{
  display: flex;
  margin-left: 50px; /*space between footer columns */
  font-size: 16px;
  color:   rgb(0, 0, 0);
  text-Decoration: none;
  margin-bottom: 0px;
}

.footer-column-mobile{
  display: none;
 }
 .footer-container-logo{
  display: none;
}
.social-media-wrap{
  display: none;
}

.footer-column:hover {
  color:rgb(60, 190, 169);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.social-media-wrap {
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  justify-content: right;
  margin-left: auto;

}
.footer-social-icons{
  color: rgb(0, 0, 0);
  font-size: 55px;
  margin: -70px auto -30px auto; /* change height of social icons, control footer height */
}

.social-icon-link {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 25px;
  padding: 2.5rem 0.6rem;
}
.social-icon-link:hover {
  color:rgb(60, 190, 169);
  transition: all 0.2s ease-out;
}

 /* Size 2 - Tablet */
  @media screen and (max-width: 960px) {
    .footer-container{
      display:table;
      grid-gap: 0px;
      max-width: 2500px;
      margin-top: 30px;
      font-size: 16px;
      background-color: rgb(255, 255, 255);
      margin-top: auto;
      margin-left: 20px;
      z-index: 999;
    }
    
    .footer-column{
      display: flex;
      grid-gap: 0px;
      max-width: 2500px;
      margin-left: 0px;
      margin-top: 0px;
      font-size: 16px;
      color: rgb(0, 0, 0)
    } 
    .social-media-wrap {
      margin-top: -50px;
      grid-gap: 0px;
      text-align: right;
      justify-content: right;
      margin: -58px auto 1px auto; /* change height of social icons */
    }
  }
   /* Size 3 - Mobile */
  @media screen and (max-width: 768px) {
    .footer-bottom-sticky{
      position:absolute;
      left:0;
      bottom:0;
      padding: 0px;
      right:0;
     }

    .footer-container-mobile{
      /*border-top: 2px solid black;*/
      background-color: rgb(255, 255, 255);
      margin-left: 20px;
      margin-right: 0;
      margin-top: auto;
      margin-top: 70px;
    }
    .footer-column-mobile{
      display: flex;
      font-size: 16px;
      color:   rgb(0, 0, 0);
      text-Decoration: none;   
    }
    .footer-column-mobile:hover {
      color:rgb(60, 190, 169);
      transition: all 0.2s ease-out;
      cursor: pointer;
    }
    .footer-container-logo{
      background-color: rgb(255, 255, 255);
      display: flex;
      margin-left: -14px;
      margin-right: 0;
      margin-top: 10px;;
      margin-bottom: 10px;;
      z-index: 999;
    }
    img.footer-logo{
      width: 60px;
      height: 55px; 
    }
    .footer-container{
      display: none;
    }
    .footer-column{
      width: 100%;
      max-width: 500px;
      margin-left:40px;
      color: rgb(0, 0, 0)
    }
   /* .social-media-wrap {
      margin-top: 0px;
      justify-content: right;
      margin: -120px auto 50px auto; 
      font-size: 55px;
    } */

    .social-media-wrap {
      display: grid;
      grid-template-columns: repeat(1, auto);
      grid-gap: 0px;
      list-style: none;
      text-align: right;
      justify-content: right;
      margin: -200px auto 1px auto; /* change height of social icons */
      margin-left: auto;
    }
    .footer-social-icons{
      text-align: right;
      margin-left: auto; /* move social icons left or right*/
    }
    .social-icon-link {
      margin-left: -40px;
      text-decoration: none;
      color: rgb(0, 0, 0);
      padding: 0rem 1.5rem; /* gap between social icons and space below */
    }
    .social-icon-link:hover {
      color: rgb(60, 190, 169);
      transition: all 0.2s ease-out;
    }
    .social-icon-link:after {
      color: black;
      transition: all 0.2s ease-out;
    }
    
   
  
  } 
